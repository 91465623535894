import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function PreferToTalk({
	title,
	message,
	phone,
	email,
	yPadding,
	hasImage,
	img,
	imgAlt,
}) {
	const talkPhone = phone || "0207 206 2610";
	const talkEmail = email || "hello@flexofficesolutions.co.uk";

	return (
		<div
			className={`bg-primary rounded bg-opacity-10 pb-${
				yPadding ? yPadding : "5"
			} pt-${yPadding ? yPadding : "5"} px-4 ps-lg-6 pe-lg-5`}
		>
			{hasImage && (
				<div
					style={{
						overflow: "hidden",
						maxHeight: "50px",
						maxWidth: "50px",
						minHeight: "50px",
						minWidth: "50px",
					}}
					className="w-100 rounded-circle mb-3"
				>
					<GatsbyImage
						image={img}
						className="rounded-circle mx-auto mb-4 greyImg"
						style={{
							maxHeight: "50px",
							maxWidth: "50px",
							minHeight: "50px",
							minWidth: "50px",
						}}
						alt={imgAlt}
					/>
				</div>
			)}
			<h4>{title || "Prefer to talk?"}</h4>
			<p>
				{message ||
					"Speak to our experts about your requirements and get a personalised shortlist."}
			</p>
			<div className="d-flex align-items-center">
				<FaPhone className="text-dark-blue fs-6 me-2" />
				<a className="text-black p-0 m-0" href={`tel:${talkPhone}`}>
					{talkPhone}
				</a>
			</div>
			<div className="d-flex pt-3 pt-lg-0 align-items-center">
				<MdEmail className="text-dark-blue fs-6 me-2" />
				<a className="text-black p-0 m-0" href={`mailto:${talkEmail}`}>
					{talkEmail}
				</a>
			</div>
		</div>
	);
}
export default PreferToTalk;
