import { Link, graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
// import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
// import gsap from "gsap";
// import { motion } from "framer-motion";
import MapGL, { Marker, NavigationControl } from "@urbica/react-map-gl";
import gsap from "gsap";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import slugify from "slugify";
import InputField from "../components/inputField";
// import { Card } from "react-bootstrap";
// import ContactUsPage from "./contact-us";
// import { MultiSelect } from "react-multi-select-component";

// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import PropertyCard from "../components/property-card";
import Form from "react-bootstrap/Form";
import Sticky from "react-stickynode";
import ListingsPropertyCard from "../components/Listings-property-card";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import PreferToTalk from "../components/prefer-to-talk";
import CartContext from "../context/CartContext";

import { FaHeart, FaRegHeart } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import { MdFormatListBulleted, MdOutlineClear } from "react-icons/md";
import ContactFormMorePeople from "../components/contact-form-more-people";
import FilterModal from "../components/filter-modal";
import ImageSlider from "../components/image-slider";
import MapCardModal from "../components/map-card-modal";
import PropertiesFilters from "../components/properties-filters";
import ExpertCard from "../components/expert-card";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const PropertiesPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			allAirtableProperties(
				filter: {
					isFloor: { nin: 1 }
					hasFeatures: { eq: 1 }
					hasPhotos: { eq: 1 }
					live: { eq: true }
				}
			) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						live
						fixedId
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						provider
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						city
						services
						amenities
						features
						photos
					}
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			paddington: wpMediaItem(title: { eq: "Bank" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			canaryWharf: wpMediaItem(title: { eq: "London Bridge" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			piccadilly: wpMediaItem(title: { eq: "Mayfair" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			westminster: wpMediaItem(title: { eq: "Shoreditch-Static-Page-square" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const paddington = data.paddington;
	const canaryWharf = data.canaryWharf;
	const piccadilly = data.piccadilly;
	const westminster = data.westminster;
	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}
	const {
		cart,
		settingVal,
		viewport,
		onLocationChange,
		numberOfPeople,
		filteredOffices,
		getDistanceFromLatLonInKm,
		distancePoint,
		OnRemoveFromCart,
		OnAddToCart,
		onLocationChangeScroll,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		idLocation,
		capacityMinMax,
		showFilterMobile,
		selectedFeatures,
		selectedOfficeType,
		distanceAmount,
		budgetMinMax,
	} = React.useContext(CartContext);

	// console.log(filteredOffices);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const [showFilter, setShowFilter] = useState(showFilterMobile);
	const handleClose = () => {
		setShowFilter(false);
		settingVal(false, "showFilterMobile");
	};
	const handleShow = () => setShowFilter(true);

	const [showForm, setShowForm] = useState(false);
	const [officeByDistance, setOfficeByDistance] = useState([]);
	const [officeByPrice, setOfficeByPrice] = useState([]);
	const [officeByPriceHigh, setOfficeByPriceHigh] = useState([]);
	const [officeAmount, setOfficeAmount] = useState(5);
	const [mapChecked, setMapChecked] = useState(true);
	const [mapMobileChecked, setMapMobileChecked] = useState(false);
	const [urlLocation, setUrlLocation] = useState("");
	var queryParameters = "";
	var uniqueLocation = "";

	var floors = [];
	useEffect(() => {
		if (numberOfPeople.min > 74) {
			const temp = capacityMinMax;
			settingVal(temp, "numberOfPeople");
			setShowForm(true);
		}
	}, []);
	useEffect(() => {
		if (typeof Window !== "undefined") {
			queryParameters = new URLSearchParams(window.location.search);
			uniqueLocation = queryParameters.get("location") || "";
			setUrlLocation(uniqueLocation);
			// console.log(uniqueLocation);
		}
	}, [idLocation]);

	useEffect(() => {
		if (typeof Window !== "undefined") {
			if (urlLocation === "Bank") {
				onLocationChange(51.5134724, -0.090159);
				settingVal("Bank, London", "locationValue");
			} else if (urlLocation === "Canary Wharf and Docklands") {
				onLocationChange(51.506156, -0.021452);
				settingVal("Canary Wharf and Docklands, London", "locationValue");
			} else if (urlLocation === "filter") {
			} else if (urlLocation === "Holborn") {
				onLocationChange(51.517431, -0.1204831);
				settingVal("Holborn, London", "locationValue");
			} else if (urlLocation === "Clerkenwell") {
				onLocationChange(51.523727, -0.105556);
				settingVal("Clerkenwell, London", "locationValue");
			} else if (urlLocation === "Covent Garden") {
				onLocationChange(51.511736, -0.122677);
				settingVal("Covent Garden, London", "locationValue");
			} else if (urlLocation === "Kings Cross") {
				onLocationChange(51.529108, -0.122413);
				settingVal("Kings Cross, London", "locationValue");
			} else if (urlLocation === "Knightsbridge") {
				onLocationChange(51.500844, -0.166965);
				settingVal("Knightsbridge, London", "locationValue");
			} else if (
				urlLocation === "London Bridge / Borough" ||
				urlLocation === "London Bridge"
			) {
				onLocationChange(51.5079111, -0.0903026);
				settingVal("London Bridge / Borough, London", "locationValue");
			} else if (urlLocation === "Marylebone") {
				onLocationChange(51.522059, -0.150455);
				settingVal("Marylebone, London", "locationValue");
			} else if (urlLocation === "Mayfair") {
				onLocationChange(51.511087, -0.147058);
				settingVal("Mayfair, London", "locationValue");
			} else if (urlLocation === "Midtown") {
				onLocationChange(51.507322, -0.127647);
				settingVal("Midtown, London", "locationValue");
			} else if (urlLocation === "Paddington") {
				onLocationChange(51.516305, -0.179242);
				settingVal("Paddington, London", "locationValue");
			} else if (urlLocation === "Shoreditch") {
				onLocationChange(51.526669, -0.079893);
				settingVal("Shoreditch, London", "locationValue");
			} else if (urlLocation === "Soho") {
				onLocationChange(51.513163, -0.131175);
				settingVal("Soho, London", "locationValue");
			} else if (urlLocation === "St Pauls") {
				onLocationChange(51.513962, -0.10114);
				settingVal("St Pauls, London", "locationValue");
			} else if (urlLocation === "Victoria") {
				onLocationChange(51.495979, -0.141317);
				settingVal("Victoria, London", "locationValue");
			} else if (urlLocation === "Piccadilly Circus") {
				settingVal("Piccadilly Circus, London", "locationValue");
				onLocationChange(51.5099786, -0.1353476);
			} else if (urlLocation === "Westminster") {
				settingVal("Westminster, London", "locationValue");
				onLocationChange(51.4975158, -0.137249);
			}
		}
	}, [urlLocation]);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	// const onPinClick = (pin, active) => {
	// 	// console.log("pin", pin, "activePopup", activePopup);

	// 	setActivePopup(pin);

	// 	setShowPopup(true);
	// };

	const [featuresProp, setFeaturesProp] = useState(selectedFeatures);
	const [type, setType] = useState(selectedOfficeType);
	const [resetting, setResetting] = useState(false);
	const [sorted, setSorted] = useState(sortedBy);
	const [radius, setRadius] = useState(distanceAmount);
	const [tempFeatures, setTempFeatures] = useState(selectedFeatures);

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	const OnMapToggleClick = () => {
		if (mapChecked) {
			var temp = !mapChecked;
			setOfficeAmount(officeAmount + 5);
			setMapChecked(temp);
		}
		if (!mapChecked) {
			var temp = !mapChecked;
			setMapChecked(temp);
		}
	};
	const OnMapMobileToggleClick = () => {
		if (mapMobileChecked) {
			var temp = !mapMobileChecked;

			setMapMobileChecked(temp);
		}
		if (!mapMobileChecked) {
			var temp = !mapMobileChecked;
			setMapMobileChecked(temp);
		}
	};

	const resetAll = () => {
		console.log("test");
		setFeaturesProp([]);
		setTempFeatures([]);
		setType("Office Type");
		setResetting(!resetting);
		setSorted("Sort");
		setRadius(1);
		settingVal("Office Type", "selectedOffice");
		settingVal(capacityMinMax, "numberOfPeople");
		settingVal(budgetMinMax, "budgetPerDesk");
		settingVal("Sort", "sortedBy");
		settingVal(1, "distanceAmount");
		settingVal([], "selectedFeatures");
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Properties",
				item: {
					url: `${siteUrl}/properties`,
					id: `${siteUrl}/properties`,
				},
			},
		],
	};

	const mapRef = useRef();

	useEffect(() => {
		const newVewport = {
			...viewport,
			latitude: viewport.latitude,
			longitude: viewport.longitude,
		};

		// onLocationChange(viewport.latitude, viewport.longitude);
		// console.log(selectedFeatures);
	}, []);

	useEffect(() => {
		const officesTemp = filteredOffices
			?.sort((p1, p2) =>
				getDistanceFromLatLonInKm(
					distancePoint.latitude,
					distancePoint.longitude,
					p1.node.locationLatitude,
					p1.node.locationLongitude
				) *
					0.621371 >
				getDistanceFromLatLonInKm(
					distancePoint.latitude,
					distancePoint.longitude,
					p2.node.locationLatitude,
					p2.node.locationLongitude
				) *
					0.621371
					? 1
					: getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							p1.node.locationLatitude,
							p1.node.locationLongitude
					  ) *
							0.621371 <
					  getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							p2.node.locationLatitude,
							p2.node.locationLongitude
					  ) *
							0.621371
					? -1
					: 0
			)
			.slice();
		// console.log(filteredOffices);
		const officesTemp2 = filteredOffices
			?.slice()
			.sort((a, b) => parseFloat(a.node.rentPM) - parseFloat(b.node.rentPM));
		const officesTemp3 = filteredOffices
			?.slice()
			.sort((a, b) => parseFloat(b.node.rentPM) - parseFloat(a.node.rentPM));
		setOfficeByDistance(officesTemp);

		setOfficeByPrice(officesTemp2);

		setOfficeByPriceHigh(officesTemp3);
	}, [filteredOffices]);

	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowDesktop, setModalShowDesktop] = React.useState(false);
	const [modalProperty, setModalProperty] = React.useState("");

	useEffect(() => {
		if (modalProperty?.node?.floorsAvailable !== null) {
			floors =
				modalProperty?.node?.floorsAvailable
					?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
					?.slice() || [];
		}
	}, [modalProperty]);

	useEffect(() => {
		const officesTemp = officeByDistance?.slice(0, officeAmount);

		const officesTemp2 = officeByPrice?.slice(0, officeAmount);

		const officesTemp3 = officeByPriceHigh?.slice(0, officeAmount);

		if (sortedBy === "Distance" || sortedBy === "Sort") {
			settingVal(officesTemp, "shortOffices");
		} else if (sortedBy === "Lowest Price") {
			settingVal(officesTemp2, "shortOffices");
		} else if (sortedBy === "Highest Price") {
			settingVal(officesTemp3, "shortOffices");
		}
	}, [officeByDistance, officeAmount, sortedBy]);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Properties | Flex Office Solutions"
				description="Find your perfect office solution with the UK's most trusted brokerage."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/properties`,
					title: "Properties | Flex Office Solutions",
					description:
						"Find your perfect office solution with the UK's most trusted brokerage.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					{/* <BgImage

						Tag="section"
						className="hero"
						id="hero-home"
						image={pluginImage}
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col xs={12}>
									<h1
										className="text-uppercase text-white"
										id="home-hero-headline"
									>
										Lorem Ipsum
									</h1>
									<p className="text-white" id="home-hero-subheading">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nunc mollis nulla justo, quis venenatis enim tempor vitae.
										In hac habitasse platea dictumst. Nam lectus risus,
										convallis vel dictum eu, interdum a mi.
									</p>
								</Col>
							</Row>
						</Container>
					</BgImage> */}

					{/* <hr className="text-med-grey py-1 my-0" /> */}
					<Container>
						<Row className="align-items-center">
							<Col xs={10} sm={11} xl={6}>
								<div className="pb-1 d-flex align-items-center">
									{/* <FaSearchLocation className="fs-2 opacity-25" /> */}
									<InputField propertiesPage />
								</div>
							</Col>
							<Col className=" pb-2 d-xl-none" xs={2} sm={1}>
								{" "}
								<a
									className="align-self-center"
									style={{ cursor: "pointer" }}
									onClick={() => OnLocationChangeGeo()}
								>
									<BiCurrentLocation className="fs-3 card-link" />
								</a>
							</Col>
							<Col className="text-end d-none d-xl-block" lg={6}>
								<Form.Check
									checked={mapChecked}
									onClick={() => OnMapToggleClick()}
									type="checkbox"
									label="Show map"
									className="d-inline-block"
								/>
							</Col>
						</Row>
					</Container>
					<hr className="text-med-grey py-0 my-0" />
					<section className=" pt-4 pb-5 ">
						<Container>
							<PropertiesFilters
								features={featuresProp}
								type={type}
								resetting={resetting}
								sorted={sorted}
								radius={radius}
								setFeatures={setFeaturesProp}
								setType={setType}
								setResetting={setResetting}
								setSorted={setSorted}
								setRadius={setRadius}
							/>

							<Row className="d-block d-xl-none  gx-3 gx-sm-0">
								<Col>
									<Row className="px-3 mw-100 justify-content-between">
										{/* <button className="btn btn-white border border-dark-grey text-dark-grey py-2 px-6">

                      <BsSliders className="fs-2" />
                    </button> */}
										<Col className="text-center" xs={6}>
											<FilterModal
												features={featuresProp}
												type={type}
												tempFeatures={tempFeatures}
												setTempFeatures={setTempFeatures}
												resetting={resetting}
												sorted={sorted}
												radius={radius}
												setFeatures={setFeaturesProp}
												setType={setType}
												setResetting={setResetting}
												setSorted={setSorted}
												setRadius={setRadius}
												handleClose={handleClose}
												handleShow={handleShow}
												showFilter={showFilter}
												setShowForm={setShowForm}
											/>
										</Col>
										<Col className="text-center" xs={6}>
											<a
												style={{ cursor: "pointer" }}
												onClick={() => OnMapMobileToggleClick()}
												className=" text-dark-grey py-2  text-center  d-inline-block"
											>
												{mapMobileChecked === false && (
													<GrMapLocation className="fs-2 text-center" />
												)}
												{mapMobileChecked === true && (
													<MdFormatListBulleted className="fs-2" />
												)}
												{mapMobileChecked === false && (
													<p className="py-2  pb-0 mb-0  d-block">Map</p>
												)}
												{mapMobileChecked === true && (
													<p className="py-2  pb-0 mb-0  d-block">Properties</p>
												)}
											</a>
										</Col>
									</Row>
								</Col>
							</Row>

							{mapChecked === true && (
								<Row id="content" className="h-100 d-none d-xl-flex">
									<Col lg={6}>
										{/* <Filters /> */}
										{showForm === true && (
											<div
												style={{
													borderRadius: "10px",
													boxShadow: "0px 1px 20px #00000029",
												}}
												className="py-3 py-md-4 px-3 px-md-5  mb-md-5 mb-3 bg-primary bg-opacity-10 "
											>
												<Row>
													<Col>
														<h2 className="fs-2">Need more than 75 desks?</h2>
														<p>
															Fill in your contact details and one of our agents
															will be in touch to discuss the options we have
															available.
														</p>
														<ContactFormMorePeople />
													</Col>
												</Row>
											</div>
										)}
										<Row
											className="position-relative mt-0 py-0 mb-0"
											style={{ index: 1 }}
										>
											{shortOffices?.map((property, i, a) => (
												<Col xs={12}>
													<div className="mb-5">
														{" "}
														<ListingsPropertyCard
															property={property}
															index={i}
														/>
														{i === (a.length > 2 ? 2 : a.length - 1) ||
														(i - 2) % 5 === 0 ? (
															<Row className="justify-content-center">
																<Col lg={8}>
																	<div className="mt-5 px-3">
																		<PreferToTalk yPadding={"4"} />
																	</div>
																</Col>
															</Row>
														) : (
															""
														)}
													</div>
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
													<Row className="mt-4 ">
														{" "}
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill text-white bg-dark-blue  py-2 me-5 mb-4"
																style={{ cursor: "pointer" }}
																onClick={resetAll}
															>
																RESET FILTERS
															</div>
														</Col>
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill border-1 border-dark-blue text-darker-blue  py-2"
																style={{ cursor: "pointer" }}
																onClick={() => {
																	settingVal(5, "distanceAmount");
																	setRadius(5);
																}}
															>
																EXPAND TO 5 MILES
															</div>
														</Col>
													</Row>
												</Col>
											)}
										</Row>
									</Col>

									<Col
										lg={6}
										style={{ minHeight: "100%" }}
										className=" position-relative "
									>
										{modalShow && (
											<MapCardModal
												show={modalShow}
												property={modalProperty}
												onHide={() => {
													setModalShow(false);
													setModalProperty("");
												}}
											/>
										)}

										<Sticky bottomBoundary="#content">
											<div className="">
												<MapGL
													ref={mapRef}
													style={{ width: "100%", height: "100vh" }}
													mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
													accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
													bounds={viewport?.bounds}
													latitude={viewport?.latitude}
													longitude={viewport?.longitude}
													zoom={viewport?.zoom}
													onViewportChange={(e) => onLocationChangePan(e)}
													viewportChangeMethod="flyTo"
												>
													<NavigationControl
														showZoom
														showCompass={false}
														position="top-right"
													/>
													{modalShowDesktop && (
														<div
															style={{ zIndex: 2 }}
															className="w-100 h-100 position-absolute start-0 top-0 "
														>
															<div
																onClick={() => {
																	setModalShowDesktop(false);
																	setModalProperty("");
																}}
																style={{ opacity: 0.45, zIndex: 2 }}
																className="w-100 h-100 start-0 top-0 bg-black
											"
															></div>
															<div
																style={{ zIndex: 4 }}
																className=" text-center position-absolute w-70 start-50 top-50 translate-middle"
															>
																<div
																	style={{ zIndex: 5 }}
																	className="bg-white h-100 w-100 px-0 mx-0 position-relative"
																>
																	<div
																		className="px-3 pt-2 position-absolute end-0 top-0"
																		style={{ zIndex: 6 }}
																	>
																		{cart?.filter(
																			(e) =>
																				e.airtableId ===
																				modalProperty?.node.airtableId
																		).length > 0 && (
																			<FaHeart
																				style={{ cursor: "pointer", zIndex: 6 }}
																				onClick={() =>
																					OnRemoveFromCart(modalProperty?.node)
																				}
																				className="fs-4 text-dark-blue"
																			/>
																		)}
																		{cart?.filter(
																			(e) =>
																				e.airtableId ===
																				modalProperty?.node.airtableId
																		).length < 1 && (
																			<FaRegHeart
																				style={{ cursor: "pointer", zIndex: 6 }}
																				onClick={() =>
																					OnAddToCart(modalProperty?.node)
																				}
																				className="fs-4 text-med-grey"
																			/>
																		)}
																	</div>
																	<div
																		className="px-3 pt-2 position-absolute start-0 top-0"
																		style={{ zIndex: 6 }}
																	>
																		<MdOutlineClear
																			style={{ cursor: "pointer" }}
																			className="fs-4"
																			onClick={() => {
																				setModalShowDesktop(false);
																				setModalProperty("");
																			}}
																		/>
																	</div>

																	<Link
																		to={`/listings/${slugify(
																			modalProperty?.node?.name
																		)}-${modalProperty?.node.fixedId}`}
																	>
																		<div
																			className="bg-white h-100 px-0 mx-0"
																			onMouseEnter={() => {
																				onLocationChangeScroll(
																					modalProperty?.node.locationLatitude,
																					modalProperty?.node.locationLongitude
																				);
																			}}
																		>
																			<Row
																				className="px-0 mx-0 g-0  align-items-center"
																				style={{
																					borderBottom: "1px solid #f5f5f5",
																				}}
																			>
																				<Col xs={12}>
																					<ImageSlider
																						imageDataArr={
																							modalProperty?.node?.photos
																						}
																						height="320px"
																					/>
																				</Col>
																				<Col
																					className="ps-4 px-lg-4 pt-3 pt-lg-1 pb-1 text-black"
																					style={{ minHeight: "100%" }}
																					xs={12}
																				>
																					<h3 className="fs-4 pb-2">
																						{modalProperty?.node?.name}
																					</h3>
																					<blockquote>
																						{modalProperty?.node.type ===
																							"Serviced Office" && (
																							<>
																								<p className="mb-1">
																									Private offices from
																								</p>
																								<h5 className="mt-1">
																									£
																									{`${
																										modalProperty?.node
																											?.rentPM === null ||
																										modalProperty?.node
																											?.rentPM === undefined
																											? "POA"
																											: `${round5(
																													modalProperty?.node
																														?.rentPM
																											  ).toLocaleString(
																													"en-UK"
																											  )}pcm`
																									}`}
																								</h5>
																							</>
																						)}
																						{modalProperty?.node.type ===
																							"Managed Office" && (
																							<>
																								<p className="mb-1">
																									Private floors from
																								</p>
																								<h5 className="mt-1">
																									£
																									{`${
																										floors[0]?.rentPM ===
																											null ||
																										floors[0]?.rentPM ===
																											undefined
																											? "POA"
																											: `${round5(
																													floors[0]?.rentPM
																											  ).toLocaleString(
																													"en-UK"
																											  )}pcm`
																									}`}
																								</h5>
																							</>
																						)}
																					</blockquote>
																				</Col>
																			</Row>
																		</div>
																	</Link>
																</div>
															</div>
														</div>
													)}
													{filteredOffices?.map((property, i) => {
														return (
															<Marker
																key={property.node.fixedId}
																longitude={property.node.locationLongitude}
																latitude={property.node.locationLatitude}
																// onClick={() => {
																// 	setModalProperty(property);
																// 	setModalShow(true);
																// }}
																onClick={() => {
																	setModalProperty(property);
																	setModalShowDesktop(true);
																}}
															>
																{(viewport?.latitude !==
																	property.node.locationLatitude ||
																	viewport?.longitude !==
																		property.node.locationLongitude) && (
																	<StaticImage
																		quality="100"
																		src={`../images/flex-pin.svg`}
																		alt="Marker"
																		placeholder="blurred"
																		style={{
																			maxWidth: "28px",
																			cursor: "pointer",
																		}}
																		formats={["auto", "webp"]}
																		className="align-middle d-inline-block"
																	/>
																)}
																{viewport?.latitude ===
																	property.node.locationLatitude &&
																	viewport?.longitude ===
																		property.node.locationLongitude && (
																		<StaticImage
																			quality="100"
																			src={`../images/flex-pin copy.svg`}
																			alt="Marker"
																			placeholder="blurred"
																			style={{
																				maxWidth: "28px",
																				cursor: "pointer",
																			}}
																			formats={["auto", "webp"]}
																			className="align-middle d-inline-block"
																		/>
																	)}
															</Marker>
														);
													})}

													{/* {showPopup && (
														<Popup
															longitude={activePopup.node.locationLongitude}
															latitude={activePopup.node.locationLatitude}
															closeButton={false}
															closeOnClick={false}
															anchor="center"
															className=" bg-t boxshadow-none map-popup-div"
														>
															<div className="p-5" onMouseLeave={onMouseExit}>
																<MapSimpleCard
																	property={activePopup}
																	closePopup={() => {
																		setModalProperty(modalProperty);
																		setModalShowDesktop(true);
																	}}
																/>
															</div>
															{console.log("active", activePopup)}
															<div className=" position-relative w-100">
                                <Button
                                  style={{ top: "0rem", right: "0rem" }}
                                  onClick={() => setShowPopup(false)}
                                  variant="secondary"
                                  className=" position-absolute"
                                >
                                  X
                                </Button>
                                <h2 className="fs-5">
                                  {activePopup.node.name}
                                </h2>
                                <GatsbyImage
                                  style={{ width: "200px", height: "150px" }}
                                  image={
                                    activePopup.node.photosNew[0]
                                      ?.childImageSharp?.gatsbyImageData
                                  }
                                  alt={activePopup.node.name}
                                />
                              </div>
														</Popup>
													)} */}
												</MapGL>
											</div>
										</Sticky>
									</Col>
								</Row>
							)}

							{mapMobileChecked === true && (
								<Row className="d-xl-none">
									<Col>
										<div className="pb-5 mt-4">
											<MapGL
												ref={mapRef}
												style={{ width: "100%", height: "70vh" }}
												mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
												accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
												bounds={viewport?.bounds}
												latitude={viewport?.latitude}
												longitude={viewport?.longitude}
												zoom={viewport?.zoom}
												onViewportChange={(e) => onLocationChangePan(e)}
												viewportChangeMethod="flyTo"
											>
												<NavigationControl
													showZoom
													showCompass={false}
													position="top-right"
												/>
												{filteredOffices?.map((property, i) => {
													return (
														<Marker
															key={property.node.fixedId}
															longitude={property.node.locationLongitude}
															latitude={property.node.locationLatitude}
															onClick={() => {
																setModalProperty(property);
																setModalShow(true);
															}}
															// onClick={() => onPinClick(property)}
														>
															{(viewport?.latitude !==
																property.node.locationLatitude ||
																viewport?.longitude !==
																	property.node.locationLongitude) && (
																<StaticImage
																	quality="100"
																	src={`../images/flex-pin.svg`}
																	alt="Marker"
																	placeholder="blurred"
																	style={{
																		maxWidth: "28px",
																		cursor: "pointer",
																	}}
																	formats={["auto", "webp"]}
																	className="align-middle d-inline-block"
																/>
															)}
															{viewport?.latitude ===
																property.node.locationLatitude &&
																viewport?.longitude ===
																	property.node.locationLongitude && (
																	<StaticImage
																		quality="100"
																		src={`../images/flex-pin copy.svg`}
																		alt="Marker"
																		placeholder="blurred"
																		style={{
																			maxWidth: "28px",
																			cursor: "pointer",
																		}}
																		formats={["auto", "webp"]}
																		className="align-middle d-inline-block"
																	/>
																)}
														</Marker>
													);
												})}
											</MapGL>
										</div>
										<div className=" ">
											<PreferToTalk yPadding={"4"} />
										</div>
									</Col>
								</Row>
							)}

							{mapChecked === false && (
								<Row id="content" className="h-100 d-none d-xl-flex">
									<Col>
										{/* <Filters /> */}
										<Row
											className="position-relative mt-0  py-0 mb-0"
											style={{ index: 1 }}
										>
											{showForm === true && (
												<Col xs={12} lg={6}>
													<div
														style={{
															borderRadius: "10px",
															boxShadow: "0px 1px 20px #00000029",
														}}
														className="py-3 py-md-4 px-3 px-md-5  mb-md-5 mb-3 bg-primary bg-opacity-10 "
													>
														<Row>
															<Col>
																<h2 className="fs-2">
																	Need more than 75 desks?
																</h2>
																<p>
																	Fill in your contact details and one of our
																	agents will be in touch to discuss the options
																	we have available.
																</p>
																<ContactFormMorePeople />
															</Col>
														</Row>
													</div>
												</Col>
											)}
											{shortOffices?.map((property, i, a) => (
												<Col className="mb-5" xs={12} lg={6}>
													{" "}
													<ListingsPropertyCard property={property} index={i} />
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
													<Row className="mt-4 ">
														{" "}
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill text-white bg-dark-blue  py-2 me-5 mb-4"
																style={{ cursor: "pointer" }}
																onClick={resetAll}
															>
																RESET FILTERS
															</div>
														</Col>
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill border-1 border-dark-blue text-darker-blue  py-2"
																style={{ cursor: "pointer" }}
																onClick={() => {
																	settingVal(5, "distanceAmount");
																	setRadius(5);
																}}
															>
																EXPAND TO 5 MILES
															</div>
														</Col>
													</Row>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							)}
							{mapMobileChecked === false && (
								<Row id="content" className="h-100 d-xl-none mt-4">
									<Col>
										{/* <Filters /> */}

										<Row
											className="position-relative mt-0 py-0 mb-0"
											style={{ index: 1 }}
										>
											{showForm === true && (
												<Col xs={12} lg={6}>
													<div
														style={{
															borderRadius: "10px",
															boxShadow: "0px 1px 20px #00000029",
														}}
														className="py-4 py-md-4 px-4 px-md-5  mb-md-5 mb-3 bg-primary bg-opacity-10 "
													>
														<Row>
															<Col>
																<h2 className="fs-2">
																	Need more than 75 desks?
																</h2>
																<p>
																	Fill in your contact details and one of our
																	agents will be in touch to discuss the options
																	we have available.
																</p>
																<ContactFormMorePeople />
															</Col>
														</Row>
													</div>
												</Col>
											)}
											{shortOffices?.map((property, i, a) => (
												<Col className="mb-5" xs={12} lg={6}>
													{" "}
													<ListingsPropertyCard property={property} index={i} />
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
													<Row className="mt-4 ">
														{" "}
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill text-white bg-dark-blue  py-2 me-5 mb-4"
																style={{ cursor: "pointer" }}
																onClick={resetAll}
															>
																RESET FILTERS
															</div>
														</Col>
														<Col md={5}>
															<div
																className="w-100 btn fw-bold rounded-pill border-1 border-dark-blue text-darker-blue  py-2"
																style={{ cursor: "pointer" }}
																onClick={() => {
																	settingVal(5, "distanceAmount");
																	setRadius(5);
																}}
															>
																EXPAND TO 5 MILES
															</div>
														</Col>
													</Row>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							)}
							{mapMobileChecked === false &&
								shortOffices?.length > 0 &&
								filteredOffices?.length > shortOffices?.length && (
									<Row>
										<Col
											lg={mapChecked === true ? 6 : 12}
											className="text-center"
										>
											<Button
												style={{ borderRadius: "200px" }}
												onClick={() => setOfficeAmount(officeAmount + 5)}
												variant="dark-blue w-100 me-md-3 w-md-auto px-4"
											>
												LOAD MORE
											</Button>
											<Button
												style={{ borderRadius: "200px" }}
												onClick={() => window.scrollTo(0, 0)}
												variant="dark-blue w-100 w-md-auto mt-3 mt-md-0 px-4 text-uppercase"
											>
												Refine Your Search
											</Button>
										</Col>
									</Row>
								)}
						</Container>
					</section>

					<section
						className="bg-white position-relative mt-4"
						style={{ zIndex: 1 }}
					>
						<Container className="bg-white my-lg-6 my-3 my-md-5">
							<Row className="g-4">
								<Col lg={6}>
									<div
										style={{
											boxShadow: "0px 1px 20px #00000029",
											borderRadius: "12px",
										}}
										className="py-3 py-md-5 px-3 px-md-5 mt-2  mb-3 bg-white"
									>
										<Row>
											<Col>
												<h2 className="mb-3 text-start">Get in touch</h2>
											</Col>
											<Row>
												<Col>
													<p className="mb-5 text-start">
														Fill in your contact details below and we'll put you
														in touch with one of our representatives
													</p>
												</Col>
											</Row>
										</Row>
										<ContactFormHome />
									</div>
									<ExpertCard />
								</Col>
								<Col lg={6} className="ps-lg-5">
									<Row className="pt-3 pt-lg-6 pb-3 pb-lg-5">
										<Col className="text-center">
											<h2>Popular Locations</h2>
										</Col>
									</Row>
									<Row className="g-3 pb-5">
										<Col xs={6}>
											<AnimatedImageCard imageData={paddington} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={canaryWharf} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={piccadilly} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={westminster} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default PropertiesPage;
